<template>
  <div>
    <!-- Followers -->
    <div v-if="followers.length > 0">
      <ion-list>

        <ion-item v-for="follower in followers" :key="follower" @click.prevent="updateRoute(follower.user.username)">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-avatar slot="start">
            <img v-if="follower.user.profile_photo_path != null && follower.user.profile_photo_path != ''" :src="follower.user.profile_photo_path">
            <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
          </ion-avatar>
          <ion-label>
            <h2>{{follower.user.name}} <ion-text color="medium">@{{follower.user.username}}</ion-text></h2>
            <h3 v-if="follower.user.profile.short_description != null && follower.user.profile.short_description != ''">
              {{follower.user.profile.short_description}}
            </h3>
            <p>{{follower.user.location.city}}, {{follower.user.location.state}}</p>
          </ion-label>
        </ion-item>

      </ion-list>
    </div>
    <!-- No Followers -->
    <div v-else class="padding-md">
      This user has no followers
    </div>
  </div>
</template>

<script>
import { loadingController, IonList, IonItem, IonAvatar, IonLabel, IonText } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  props: ['username'],
  name: 'UserFollowerList',
  components: { IonList, IonItem, IonAvatar, IonLabel, IonText },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const userId = ref(0);
    const followers = ref([]);
    const timeout = { default: 6000 }

    onMounted(() => {
      getUserId()
    })

    function goBack() {
      router.go(-1)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    function getUserId() {
      presentLoading()

      apiClient.get('/api/get/user_id/'+props.username,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          userId.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function getFollowers() {
      presentLoading()

      apiClient.get('/api/get/followers/user_id/'+userId.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          followers.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function updateRoute(val) {
      router.push('/profile/'+val)
    }

    return {
      router, goBack, store, getFollowers, followers, userId, updateRoute
    }
  },
  methods: {

  },
  watch: {
    userId: function() {
      if(this.userId > 0) {
        this.getFollowers()
      }
    }
  }
});
</script>

<style scoped>
ion-thumbnail {
   --size: 80px;
}
</style>