<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="text-capitalize">Followers</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">

        <ion-toolbar color="light">
          <ion-segment scrollable value="avatars">
            <ion-segment-button value="avatars" @click="updateProfileView('avatars')">
              <ion-icon :icon="apps" class="small" />
            </ion-segment-button>
            <ion-segment-button value="list" @click="updateProfileView('list')">
              <ion-icon :icon="reorderThree" class="small" />
            </ion-segment-button>
            <ion-segment-button value="options" @click.prevent="updateRoute('/profile-sort')">
              <ion-icon :icon="options" class="small" />
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <div v-if="username != null && username != undefined">
          <!-- Avatars -->
          <div v-if="profileView == 'avatars'">
            <UserFollowers :username="username" />
          </div>
          <!-- List -->
          <div v-if="profileView == 'list'">
            <UserFollowerList :username="username" />
          </div>
        </div>
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="followers" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, IonSegment, IonSegmentButton } from '@ionic/vue';
import { chevronBack, apps, reorderThree, options } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import UserFollowers from '../../components/UserFollowers.vue';
import UserFollowerList from '../../components/UserFollowerList.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Followers',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, Tabs, UserFollowers, IonSegment, IonSegmentButton, UserFollowerList
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const username = ref(null);
    const profileView = ref('avatars');

    onMounted(() => {
      username.value = router.currentRoute._rawValue.params.username
    })

    function goBack() {
      router.go(-1)
    }

    function updateProfileView(val) {
      profileView.value = val
    }

    function updateRoute(val) {
      router.push(val)
    }

    return {
      chevronBack, router, goBack, username, apps, reorderThree, options, profileView, updateRoute, updateProfileView
    }
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>